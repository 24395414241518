<template>
  <div class="d-flex flex-row flex-column-fluid page">
    <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid">
      <!-- begin:: Header -->
      <div id="kt_content" class="content d-flex flex-column p-0">
        <div class="top-section">
          <div
            class="d-flex"
            id="bg"
            :style="{ backgroundImage: `url(${backgroundImage})` }"
            alt="Photo by Damian Patkowski on Unsplash"
          >
            <div class="container leading-text">
              <h1 class="leading-title text-white font-weight-bolder">
                Home of African Apps
              </h1>
              <p class="text-white leading-subtitle">
                Get diapps and get the best Africa has to offer
              </p>
              <a
                href="/diapps.apk"
                class="btn btn-primary btn-rounded index-initial leading-btn text-uppercase"
                >download diapps
              </a>
            </div>
          </div>
          <KTHeader></KTHeader>
        </div>
        <!-- end:: Header -->
        <!-- Page Body -->
        <div class="d-flex flex-column-fluid">
          <div
            :class="{
              'container-fluid': contentFluid,
              container: !contentFluid,
            }"
          >
            <div class="pt-7">
              <div id="features" style="padding: 0rem 8rem 8rem 0px">
                <div class="f-flex">
                  <h1
                    class="display-1 text-primary mb-7 pb-7 text-center font-weight-bolder"
                  >
                    Features
                  </h1>
                </div>
                <div class="row text-center">
                  <div class="col-md-4 p-3">
                    <div class="card p-4">
                      <svg
                        class="mx-auto p-4 elevated"
                        width="104px"
                        height="103px"
                        viewBox="197 173 104 103"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                      >
                        <!-- Generator: Sketch 41.2 (35397) - http://www.bohemiancoding.com/sketch -->
                        <desc>Created with Sketch.</desc>
                        <defs></defs>
                        <g
                          id="Group-2"
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                          transform="translate(198.000000, 173.000000)"
                        >
                          <rect
                            id="Rectangle"
                            fill="#834736"
                            transform="translate(51.247918, 51.247918) rotate(-15.000000) translate(-51.247918, -51.247918) "
                            x="9.40416821"
                            y="9.40416821"
                            width="83.6875"
                            height="83.6875"
                            rx="8"
                          ></rect>
                          <rect
                            id="Rectangle-Copy"
                            fill="rgba(131, 71, 54, 0.63)"
                            x="9.0125"
                            y="9.0125"
                            width="83.6875"
                            height="83.6875"
                            rx="8"
                          ></rect>
                          <g
                            id="download"
                            transform="translate(22.531250, 24.462500)"
                            fill="#FFFFFF"
                          >
                            <g id="Group">
                              <path
                                d="M55.7091346,35.6208333 L51.2524038,35.6208333 C49.9153846,35.6208333 49.0240385,36.5113542 49.0240385,37.8471354 L49.0240385,43.4128906 C49.0240385,44.0807813 48.5783654,44.5260417 47.9098558,44.5260417 L10.0276442,44.5260417 C9.35913462,44.5260417 8.91346154,44.0807813 8.91346154,43.4128906 L8.91346154,37.8471354 C8.91346154,36.5113542 8.02211538,35.6208333 6.68509615,35.6208333 L2.22836538,35.6208333 C0.891346154,35.6208333 0,36.5113542 0,37.8471354 L0,51.2049479 C0,52.5407292 0.891346154,53.43125 2.22836538,53.43125 L55.7091346,53.43125 C57.0461538,53.43125 57.9375,52.5407292 57.9375,51.2049479 L57.9375,37.8471354 C57.9375,36.5113542 57.0461538,35.6208333 55.7091346,35.6208333 Z"
                                id="Shape"
                              ></path>
                              <path
                                d="M27.4088942,34.9529427 C27.8545673,35.3982031 28.5230769,35.6208333 28.96875,35.6208333 C29.4144231,35.6208333 30.0829327,35.3982031 30.5286058,34.9529427 L43.8987981,21.5951302 C44.3444712,21.1498698 44.5673077,20.7046094 44.5673077,20.0367188 C44.5673077,19.3688281 44.3444712,18.9235677 43.8987981,18.4783073 L40.7790865,15.3614844 C40.3334135,14.916224 39.8877404,14.6935938 39.2192308,14.6935938 C38.5507212,14.6935938 38.1050481,14.916224 37.659375,15.3614844 L35.4310096,17.5877865 C34.7625,18.2556771 33.4254808,17.8104167 33.4254808,16.6972656 L33.4254808,2.22630208 C33.4254808,0.890520833 32.5341346,0 31.1971154,0 L26.7403846,0 C25.4033654,0 24.5120192,0.890520833 24.5120192,2.22630208 L24.5120192,16.9198958 C24.5120192,17.8104167 23.3978365,18.4783073 22.5064904,17.8104167 L20.278125,15.5841146 C19.8324519,15.1388542 19.3867788,14.916224 18.7182692,14.916224 C18.0497596,14.916224 17.6040865,15.1388542 17.1584135,15.5841146 L14.0387019,18.7009375 C13.5930288,19.1461979 13.3701923,19.5914583 13.3701923,20.259349 C13.3701923,20.9272396 13.5930288,21.3725 14.0387019,21.8177604 L27.4088942,34.9529427 L27.4088942,34.9529427 Z"
                                id="Shape"
                              ></path>
                            </g>
                          </g>
                        </g>
                      </svg>
                      <h3 class="display-4 mt-4 text-dark font-weight-bolder">
                        Download Apps
                      </h3>
                      <p class="text-dark">
                        One stop store for all your African apps
                      </p>
                    </div>
                  </div>
                  <div class="col-md-4 p-3">
                    <div class="card p-4">
                      <svg
                        class="mx-auto p-4"
                        width="104px"
                        height="103px"
                        viewBox="428 173 104 103"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                      >
                        <!-- Generator: Sketch 41.2 (35397) - http://www.bohemiancoding.com/sketch -->
                        <desc>Created with Sketch.</desc>
                        <defs></defs>
                        <g
                          id="Share"
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                          transform="translate(429.000000, 173.000000)"
                        >
                          <rect
                            id="Rectangle"
                            fill="#834736"
                            transform="translate(51.247918, 51.247918) rotate(-15.000000) translate(-51.247918, -51.247918) "
                            x="9.40416821"
                            y="9.40416821"
                            width="83.6875"
                            height="83.6875"
                            rx="8"
                          ></rect>
                          <rect
                            id="Rectangle-Copy"
                            fill="rgba(131, 71, 54, 0.63)"
                            x="9.0125"
                            y="9.0125"
                            width="83.6875"
                            height="83.6875"
                            rx="8"
                          ></rect>
                          <g
                            id="share"
                            transform="translate(24.000000, 24.000000)"
                            fill="#FFFFFF"
                          >
                            <g id="Capa_1">
                              <path
                                d="M44.0631957,34.315519 C40.9107447,34.315519 38.1300894,35.8047342 36.3133915,38.0852658 L19.4630936,29.5407342 C19.6836894,28.7249241 19.8422426,27.882 19.8422426,26.9948734 C19.8422426,26.0291392 19.6539319,25.1199114 19.3911702,24.2351772 L36.1671319,15.7300633 C37.973834,18.1138101 40.8264128,19.6718354 44.0656085,19.6718354 C49.5526979,19.6718354 53.991383,15.2706076 53.991383,9.83472152 C53.9914979,4.40373418 49.5526979,0 44.0657234,0 C38.5910426,0 34.147417,4.40373418 34.147417,9.83460759 C34.147417,10.7242405 34.3060851,11.569557 34.5290936,12.3878734 L17.6812085,20.9324051 C15.8620979,18.649481 13.0763872,17.1553671 9.91899574,17.1553671 C4.43684681,17.1553671 0.000689361702,21.5614937 0.000689361702,26.9948734 C0.000689361702,32.4282532 4.4369617,36.8319873 9.91899574,36.8319873 C13.1631319,36.8319873 16.0132979,35.2690633 17.8273532,32.8829241 L34.5958468,41.388038 C34.3330851,42.2702658 34.1423617,43.1868987 34.1423617,44.1551392 C34.1423617,49.588519 38.5859872,53.9922532 44.0606681,53.9922532 C49.5477574,53.9922532 53.9864426,49.588519 53.9864426,44.1551392 C53.9889702,38.7168608 49.5502851,34.315519 44.0631957,34.315519 Z"
                                id="Shape"
                              ></path>
                            </g>
                          </g>
                        </g>
                      </svg>
                      <h3 class="display-4 mt-4 text-dark font-weight-bolder">
                        Share Apps
                      </h3>
                      <p class="text-dark">
                        Share apps over bluetooth with other diapps users
                      </p>
                    </div>
                  </div>
                  <div class="col-md-4 p-3">
                    <div class="card p-4">
                      <svg
                        class="mx-auto p-4 elevated"
                        width="104px"
                        height="103px"
                        viewBox="659 173 104 103"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                      >
                        <!-- Generator: Sketch 41.2 (35397) - http://www.bohemiancoding.com/sketch -->
                        <desc>Created with Sketch.</desc>
                        <defs></defs>
                        <g
                          id="Locale-Sensetive"
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                          transform="translate(660.000000, 173.000000)"
                        >
                          <rect
                            id="Rectangle"
                            fill="#834736"
                            transform="translate(51.247918, 51.247918) rotate(-15.000000) translate(-51.247918, -51.247918) "
                            x="9.40416821"
                            y="9.40416821"
                            width="83.6875"
                            height="83.6875"
                            rx="8"
                          ></rect>
                          <rect
                            id="Rectangle-Copy"
                            fill="rgba(131, 71, 54, 0.63)"
                            x="9.0125"
                            y="9.0125"
                            width="83.6875"
                            height="83.6875"
                            rx="8"
                          ></rect>
                          <g
                            id="download"
                            transform="translate(22.531250, 24.462500)"
                            fill="#FFFFFF"
                          >
                            <g id="Group">
                              <path
                                d="M55.7091346,35.6208333 L51.2524038,35.6208333 C49.9153846,35.6208333 49.0240385,36.5113542 49.0240385,37.8471354 L49.0240385,43.4128906 C49.0240385,44.0807813 48.5783654,44.5260417 47.9098558,44.5260417 L10.0276442,44.5260417 C9.35913462,44.5260417 8.91346154,44.0807813 8.91346154,43.4128906 L8.91346154,37.8471354 C8.91346154,36.5113542 8.02211538,35.6208333 6.68509615,35.6208333 L2.22836538,35.6208333 C0.891346154,35.6208333 0,36.5113542 0,37.8471354 L0,51.2049479 C0,52.5407292 0.891346154,53.43125 2.22836538,53.43125 L55.7091346,53.43125 C57.0461538,53.43125 57.9375,52.5407292 57.9375,51.2049479 L57.9375,37.8471354 C57.9375,36.5113542 57.0461538,35.6208333 55.7091346,35.6208333 Z"
                                id="Shape"
                              ></path>
                              <path
                                d="M27.4088942,34.9529427 C27.8545673,35.3982031 28.5230769,35.6208333 28.96875,35.6208333 C29.4144231,35.6208333 30.0829327,35.3982031 30.5286058,34.9529427 L43.8987981,21.5951302 C44.3444712,21.1498698 44.5673077,20.7046094 44.5673077,20.0367188 C44.5673077,19.3688281 44.3444712,18.9235677 43.8987981,18.4783073 L40.7790865,15.3614844 C40.3334135,14.916224 39.8877404,14.6935938 39.2192308,14.6935938 C38.5507212,14.6935938 38.1050481,14.916224 37.659375,15.3614844 L35.4310096,17.5877865 C34.7625,18.2556771 33.4254808,17.8104167 33.4254808,16.6972656 L33.4254808,2.22630208 C33.4254808,0.890520833 32.5341346,0 31.1971154,0 L26.7403846,0 C25.4033654,0 24.5120192,0.890520833 24.5120192,2.22630208 L24.5120192,16.9198958 C24.5120192,17.8104167 23.3978365,18.4783073 22.5064904,17.8104167 L20.278125,15.5841146 C19.8324519,15.1388542 19.3867788,14.916224 18.7182692,14.916224 C18.0497596,14.916224 17.6040865,15.1388542 17.1584135,15.5841146 L14.0387019,18.7009375 C13.5930288,19.1461979 13.3701923,19.5914583 13.3701923,20.259349 C13.3701923,20.9272396 13.5930288,21.3725 14.0387019,21.8177604 L27.4088942,34.9529427 L27.4088942,34.9529427 Z"
                                id="Shape"
                                transform="translate(28.968750, 17.810417) rotate(-180.000000) translate(-28.968750, -17.810417) "
                              ></path>
                            </g>
                          </g>
                        </g>
                      </svg>
                      <h3 class="display-4 mt-4 text-dark font-weight-bolder">
                        Free Publishing
                      </h3>
                      <p class="text-dark">
                        Publish your own apps for free through diapps
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="one_more_thing" class="p-7 mb-7">
          <div class="container">
            <h1 class="leading-title">One More Thing</h1>
            <p class="text-dark">
              You can also publish your apps with us. Sign up to create an
              account and navigate to your developer dashboard
            </p>
          </div>
        </div>
        <div class="d-flex flex-column-fluid">
          <div
            :class="{
              'container-fluid': contentFluid,
              container: !contentFluid,
            }"
          >
            <div class="prev">
              <!-- <div class="col-md-6">
                  <img src="assets/img/app.png" id="phone" />
                </div> -->
              <div class="row" style="margin-bottom: 110px">
                <div class="col-md-12">
                  <div class="col-md-10">
                    <!-- <h6 class="text-info">Download App</h6> -->
                    <h3 class="display-4 text-dark">
                      diapps - Mobile AppStore
                    </h3>
                    <p class="text-dark">
                      Get diapps now to unlock the best of African content
                    </p>
                    <button
                      onclick="window.location.href='/diapps.apk'"
                      type="button"
                      class="btn btn-primary btn-rounded index-initial leading-btn text-uppercase"
                      aria-label="Left Align
                                                "
                      style="
                        letter-spacing: 0.05em;
                        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
                          0 3px 1px -2px rgba(0, 0, 0, 0.2),
                          0 1px 5px 0 rgba(0, 0, 0, 0.12);
                      "
                    >
                      download diapps
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <KTFooter />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import KTFooter from "@/view/layout/footer/Footer.vue";
import KTHeader from "@/view/layout/header/Header.vue";
import KTHeaderMobile from "@/view/layout/header/HeaderMobile.vue";
import KTScrollTop from "@/view/layout/extras/ScrollTop";
import Loader from "@/view/content/Loader.vue";
import HtmlClass from "@/core/services/htmlclass.service";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";
export default {
  components: {
    KTHeader,
    KTHeaderMobile,
    KTFooter,
    KTScrollTop,
    Loader,
  },
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init(this.layoutConfig());
  },
  mounted() {
    // Simulate the delay page loading
    setTimeout(() => {
      // Remove page loader after some time
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    }, 1000);
  },
  computed: {
    ...mapGetters(["breadcrumbs", "pageTitle", "layoutConfig"]),

    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      // return !!this.layoutConfig("toolbar.display");
      return true;
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    },
    backgroundImage() {
      return process.env.BASE_URL + "media/bg/africa.jpg";
    },
  },
};
</script>

<style scoped>
#one_more_thing {
  background-color: gainsboro;
  padding: 10rem !important;
  margin-bottom: 9rem !important;
}
.card {
  border-radius: 2rem;
}
#bg {
  height: 85vh;
  width: 90vw;
  position: absolute;
  top: 10vh;
  bottom: 5vh;
  left: 5vw;
  right: 5vw;
  border-radius: 20px;
  background-size: cover;
}
.leading-text {
  align-self: flex-end;
  margin-bottom: 50px;
}
.btn-rounded {
  border-radius: 50px;
}
.index-initial {
  z-index: initial;
}
.top-section {
  height: 100vh;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
}
#kt_wrapper {
  padding-left: 0px !important;
}
#kt_header {
  left: 0px !important;
}
.leading-title {
  font-size: 6.5rem;
}
.leading-subtitle {
  font-size: 1.1rem;
}
.leading-btn {
  font-size: 1.5rem;
  font-family: "Lexend Deca", sans-serif !important;
  padding-left: 2rem;
  padding-right: 2rem;
  font-weight: 600;
}
</style>
